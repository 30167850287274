@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Light'), local('OpenSans-Light'), url('opensanslight.woff2') format('woff2'), url('opensanslight.woff') format('woff'), url('opensanslight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url('opensanslightitalic.woff2') format('woff2'), url('opensanslightitalic.woff') format('woff'), url('opensanslightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), local('OpenSans'), url('opensans.woff2') format('woff2'), url('opensans.woff') format('woff'), url('opensans.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url('opensansitalic.woff2') format('woff2'), url('opensansitalic.woff') format('woff'), url('opensansitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('opensanssemibold.woff2') format('woff2'), url('opensanssemibold.woff') format('woff'), url('opensanssemibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'), url('opensanssemibolditalic.woff2') format('woff2'), url('opensanssemibolditalic.woff') format('woff'), url('opensanssemibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url('opensansbold.woff2') format('woff2'), url('opensansbold.woff') format('woff'), url('opensansbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url('opensansbolditalic.woff2') format('woff2'), url('opensansbolditalic.woff') format('woff'), url('opensansbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url('opensansextrabold.woff2') format('woff2'), url('opensansextrabold.woff') format('woff'), url('opensansextrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'), url('opensansextrabolditalic.woff2') format('woff2'), url('opensansextrabolditalic.woff') format('woff'), url('opensansextrabolditalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: block;
}
